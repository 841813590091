const ALL_PARTNERS = [
  {
    name: 'Reaction Sécurité',
    imageUrl:
      'https://s3.eu-west-3.amazonaws.com/www.leadadvisor.fr/logo-reaction-securite.png',
    link: 'http://reaction-securite.fr',
    description: 'La sécurité est notre priorité',
    codeDepartement: '78',
    address: '4 Rue de la Baume',
    codeAndCity: '75008 Paris',
  },
  {
    name: 'En Toute Sécurité',
    imageUrl:
      'https://files.graph.cool/cjbuxfggc0ptd0127peyss7np/cjdn1zxjb054c0106kbs5ulrs',
    link: 'https://www.entoutesecurite.fr',
    description:
      'SOCIÉTÉ de SÉCURITÉ et de GARDIENNAGE à Paris ET RÉGION PARISIENNE',
    city: 'Saint-Denis',
    codeDepartement: '93',
    address: 'Immeuble Ornano 1 Axe Paris Pleyel 32 ter Boulevard Ornano',
    codeAndCity: '93 200 Saint-Denis',
  },
  {
    name: 'GSA FRANCE',
    imageUrl:
      'https://files.graph.cool/cjbuxfggc0ptd0127peyss7np/cjdbgwse303eh0102rclytx04',
    link: 'https://www.gsa-france.fr/',
    description: 'Savoir comment faire, le faire bien, pour vous.',
    codeDepartement: '69',
  },
  {
    name: 'SECURYTER ADS',
    imageUrl:
      'https://s3.eu-west-3.amazonaws.com/www.leadadvisor.fr/LOGOSECURYTERADS.png',
    link: 'http://www.securyter.fr',
    description:
      'Un service de sécurité de haute qualité pour une clientèle exigeante',
    codeDepartement: '94',
    address: '3 Allée des Erables',
    codeAndCity: '94000 Créteil',
  },
  {
    name: 'ACR SÉCURITÉ',
    imageUrl:
      'https://files.graph.cool/cjbuxfggc0ptd0127peyss7np/cjdodgmqx0dzz0106yphfh6ne',
    link: 'http://www.acrsecurite.fr',
    description: 'Un oeil sur vos biens',
    codeDepartement: '66',
    address: '77 Avenue Jean Jaurès',
    codeAndCity: '66670 Bages',
  },
  {
    name: 'Sirius Sécurité Privée',
    imageUrl:
      'https://cdn.website-editor.net/ee8bc07c0ec54752b9a7d9596f5ec053/dms3rep/multi/thumbnail/LOGO+SSP.png',
    link: 'https://www.sirius-securite.com',
    city: 'Levallois-Perret',
    codeDepartement: '92',
    address: '69 rue Louise Michel',
    codeAndCity: '92300  Levallois-Perret',
  },
  {
    name: 'Axcelis Sécurité',
    imageUrl:
      'https://s3.eu-west-3.amazonaws.com/www.leadadvisor.fr/axcelis.jpg',
    link: 'http://www.axcelis-securite.fr',
    description: 'Une réponse à chacun de vos risques',
    city: 'Geispolsheim',
    codeDepartement: '67',
    address: '5 rue Ampère',
    codeAndCity: '67118 GEISPOLSHEIM',
  },
  {
    name: 'La Licorne',
    imageUrl:
      'https://s3.eu-west-3.amazonaws.com/www.leadadvisor.fr/licorne.jpg',
    link: 'https://www.lalicornesecurite.com/',
    description:
      'Votre Sécurité ? Permettez que nous en fassions notre affaire.',
    city: 'Épinay-sur-Seine',
    codeDepartement: '93',
    address: '19-21 Rue Des Saules',
    codeAndCity: '93800 ÉPINAY-SUR-SEINE',
  },
  {
    name: 'Falk Sécurité',
    imageUrl: 'https://s3.eu-west-3.amazonaws.com/www.leadadvisor.fr/falk.png',
    link: 'https://falksecurite.fr/',
    description:
      'Une société de sécurité à votre écoute et réactive à toutes vos demandes.',
    codeDepartement: '26',
    address: '18 Rue Claude Bernard ',
    codeAndCity: '26100 Romans sur Isère',
  },
  {
    name: 'TELEVEIL',
    darkBackground: true,
    imageUrl:
      'https://s3.eu-west-3.amazonaws.com/www.leadadvisor.fr/Logo-televeil%402x.png',
    link: 'http://televeil.fr',
    description:
      'Services et solutions de sécurité électronique adaptés à vos besoins. Location toute durée.',
    codeDepartement: '92',
    address: '13/15/17 Avenue Marc Sangnier',
    codeAndCity: '92390 Villeneuve-la-Garenne',
  },
  {
    name: 'Acces Sécurité',
    imageUrl:
      'https://s3.eu-west-3.amazonaws.com/www.leadadvisor.fr/1434478522.jpg',
    link: 'http://www.acces-securite.fr',
    description: 'Société de sécurité pour professionels & particuliers',
    codeDepartement: '30',
    address: '433 rue le Corbusier - ZAC de Grézan',
    codeAndCity: '30000 Nîmes',
  },
  {
    name: 'Ass Sécurité',
    imageUrl:
      'https://s3.eu-west-3.amazonaws.com/www.leadadvisor.fr/ass-securite.jpg',
    link: 'https://www.asssecurite.com/',
    description: 'Ass Sécurité la solution pour votre sécurité',
    codeDepartement: '84',
    address: '195 rue Ducres',
    codeAndCity: '84700 Sorgues',
  },
];

export default ALL_PARTNERS;
