import { Box } from 'rebass';
import styled from 'styled-components';

export default styled(Box).attrs({
  pt: 10,
  pb: 10,
  pl: 15,
  pr: 15,
  color: '#fff',
})`
  min-height: 0px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 2px;
  background: ${props => props.theme.colors.sub};
`;
