import React from 'react';
import Loader from './Loader';

const VARIANTS = {
  primary:
    'border-transparent bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white',
  secondary:
    'border-transparent bg-indigo-100 hover:bg-indigo-200 focus:ring-indigo-500 text-indigo-700',
  success:
    'border-transparent bg-teal-500 hover:bg-teal-600 focus:ring-green-400 text-white',
  danger:
    'border-transparent bg-red-100 hover:bg-red-200 focus:ring-red-500 text-red-700',
  outline:
    'border-gray-300 bg-white hover:bg-gray-50 focus:ring-indigo-500 text-gray-700',
};

type ButtonVariant = keyof typeof VARIANTS;

export default function Button({
  variant = 'primary',
  loading = false,
  type = 'button',
  icon,
  children,
  className = '',
  ...props
}: {
  variant?: ButtonVariant;
  loading?: boolean;
  type?: 'button' | 'submit';
  icon?: string | React.ReactNode;
  children: React.ReactNode;
  className?: string;
} & React.HTMLProps<HTMLButtonElement>) {
  return (
    <button
      className={`disabled:opacity-50 disabled:pointer-events-none inline-flex items-center justify-center px-4 py-2 border shadow-sm text-base font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${VARIANTS[variant]} ${className}`}
      type={type}
      {...props}
    >
      {loading ? <Loader className="w-6 h-6 text-white" /> : children}
    </button>
  );
}
