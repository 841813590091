import React from 'react';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';

import Button from './ui/Button';
import Error from './ui/Error';
import Success from './ui/Success';

const Input = styled.input`
  background-color: ${(props) => (props.inverted ? 'transparent' : '#fff')};
  color: ${(props) => (props.inverted ? '#fff' : '#000')};
  padding: 5px 10px;
  box-shadow: 0 0 0 1pt
    ${(props) => (props.inverted ? props.theme.colors.sub : '#bbb')};
  transition: box-shadow 300ms ease;
  font-size: 1rem;
  font-family: 'Source Sans Pro', AdobeInvisFont, MyriadPro-Regular, sans-serif;
  border-radius: 3px;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  border: 0;

  ::placeholder {
    color: #666;
    font-weight: 100;
  }

  &:focus,
  &:active {
    box-shadow: 0 0 0 2pt ${(props) => props.theme.colors.sub};
  }

  &:disabled {
    background-color: #eee;
    color: #888;
  }
`;

const HiddenInput = styled.input`
  opacity: 0;
  position: absolute;
`;

const Container = styled.div`
  line-height: 20px;
  width: 100%;

  input[type='checkbox']:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
`;

const Label = styled.label`
  position: relative;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  font-style: normal;
  color: #003b5c;
  text-align: left;

  ::before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    border: 2px solid transparent;

    @media (max-width: 766px) {
      top: 50%;
      transform: translateY(-50%);
    }
    background-color: ${(props) => (props.checked ? '#b3ffb7' : '#fff')};
    overflow: hidden;
    cursor: pointer;
    box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.2);
    left: 0;
    top: calc(50% - 10px);
  }

  ::after {
    cursor: pointer;
    position: absolute;
    height: 9px;
    width: 15px;
    content: '';
    border-left: 4.5px solid;
    border-bottom: 4.5px solid;
    border-color: ${(props) => (props.checked ? '#40c540' : 'transparent')};
    transform: rotate(-45deg) translate3d(-50%, 0, 0);
    transform-origin: center center;
    transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
    left: 9px;
    right: 0;
    top: ${(props) => (props.checked ? 'calc(50% - 20px + 9px)' : '100%')};
  }

  @media (max-width: 766px) {
    padding-left: 30px;
  }
`;

const Checkbox = ({ label, onChange, id, checked, required, ...rest }) => {
  return (
    <Container>
      <HiddenInput
        type="checkbox"
        checked={checked}
        id={id}
        onChange={onChange}
        required={required}
        {...rest}
      />
      <Label htmlFor={id} checked={checked}>
        <div
          css={`
            margin-left: 40px;
          `}
        >
          {label}
        </div>
      </Label>
    </Container>
  );
};

export default () => {
  const [hasAcceptedDataUsage, setHasAcceptedDataUsage] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);

  return (
    <Box
      p={4}
      css={`
        background: #fff;
        overflow: auto;
        margin: 40px 0;
        border-radius: 6px;
        box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
          0 3px 6px 0 rgba(0, 0, 0, 0.07);
      `}
    >
      <p
        css={`
          color: rgb(11, 173, 98);
          font-size: 1.5rem;
          font-weight: 600;
        `}
      >
        Téléchargez gratuitement et sans aucun engagement de votre part nos
        modèles de CV d’agent de sécurité et de lettre de motivation
      </p>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (isLoading) {
            return;
          }
          setIsLoading(true);
          setError(null);
          setSuccess(false);

          try {
            const response = await fetch(
              `${process.env.GATSBY_LEADADVISOR_API_URL ||
                'http://localhost:3000'}/api/cv-models`,
              {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  email,
                }),
              }
            );

            if (!response.ok) {
              throw new Error(response.statusText);
            }

            setSuccess(true);
          } catch (error) {
            console.log(error);
            setError(
              'Une erreur est survenue. Veuillez rééssayer ultérieurement ou contacter le support.'
            );
          } finally {
            setIsLoading(false);
          }
        }}
      >
        {!!error && <Error my={3}>{error}</Error>}
        {!!success && (
          <Success my={3}>
            Les modèles ont été envoyés à l’adresse <b>{email}</b>
          </Success>
        )}
        <div
          css={`
            display: block;
            font-family: 'Source Sans Pro', AdobeInvisFont, MyriadPro-Regular,
              sans-serif;
            font-size: 1.2rem;
            color: #003b5c;
            font-weight: 700;
            margin-bottom: 0.3rem;
          `}
        >
          Votre adresse email
        </div>
        <Input
          name="email"
          type="email"
          value={email}
          placeholder="jean.dupont@gmail.com"
          onChange={(e) => {
            setEmail(e.target.value);
            if (success) {
              setSuccess(false);
            }
          }}
          required
        />
        <Box mt={3}>
          <Checkbox
            required
            id="data-usage"
            name="data-usage"
            checked={hasAcceptedDataUsage}
            onChange={() => setHasAcceptedDataUsage(!hasAcceptedDataUsage)}
            label="En soumettant ce formulaire, j’accepte que Lead Advisor exploite mes coordonnées pour me contacter au sujet de ses services."
          />
        </Box>
        <Flex>
          <Box ml="auto" mt={3}>
            <Button type="submit" loading={isLoading}>
              Télécharger les modèles
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};
