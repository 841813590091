// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agent-de-securite-tsx": () => import("./../../../src/pages/agent-de-securite.tsx" /* webpackChunkName: "component---src-pages-agent-de-securite-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-big-city-tsx": () => import("./../../../src/templates/BigCity.tsx" /* webpackChunkName: "component---src-templates-big-city-tsx" */),
  "component---src-templates-city-tsx": () => import("./../../../src/templates/City.tsx" /* webpackChunkName: "component---src-templates-city-tsx" */),
  "component---src-templates-department-tsx": () => import("./../../../src/templates/Department.tsx" /* webpackChunkName: "component---src-templates-department-tsx" */),
  "component---src-templates-randomized-city-tsx": () => import("./../../../src/templates/RandomizedCity.tsx" /* webpackChunkName: "component---src-templates-randomized-city-tsx" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

