import React from 'react';

export function CTA({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) {
  return (
    <div className="flex justify-center">
      <a className="leadadvisor-button-link" href={href}>
        <button className="leadadvisor-button" type="button">
          {children}
        </button>
      </a>
    </div>
  );
}
