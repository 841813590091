import React from 'react';
import { Box } from 'rebass';
import slugify from '@sindresorhus/slugify';
import ALL_PARTNERS from '../partners';
import CITIES_COMPANIES from '../locations/cities-companies.json';
import CITIES_DATA from '../locations/cities-with-crime-company-department-data.json';

export default function CityCompanies({ departmentCode, cityName }) {
  const companies = CITIES_COMPANIES[cityName];
  const companyCount = CITIES_DATA[cityName];
  const partners = ALL_PARTNERS.filter(
    (partner) => partner.codeDepartement === departmentCode
  );

  return (
    <Box>
      {partners.length > 0 ? (
        <Box>
          <p>Voici nos partenaires intervenants à {cityName} :</p>
          {partners.map((partner) => (
            <Box my={3}>
              <a href={partner.link} target="_blank" rel="noopener noreferrer">
                {partner.name}
              </a>
              <Box>{partner.address}</Box>
              <Box>{partner.codeAndCity}</Box>
              <Box>
                <img src={partner.imageUrl} alt={partner.name} />
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <>
          <p>
            Au 20 février 2020, on dénombre {companyCount} entreprises de
            sécurité à {cityName}. Voici quelques-unes de ces sociétés :
          </p>
          <Box>
            {companies.slice(0, 10).map((company) => (
              <Box my={3}>
                <Box>{company.name}</Box>
                <Box>{company.address}</Box>
                <Box>{company.codeAndCity}</Box>
              </Box>
            ))}
          </Box>
          <p>
            <a
              href={`https://www.societe.com/cgi-bin/liste?ens=on&nom=&ape=8010Z&adr=&num=&ville=${slugify(
                cityName
              )}&dep=`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Liste complète des entreprises de sécurité à {cityName}
            </a>
          </p>
        </>
      )}
    </Box>
  );
}
